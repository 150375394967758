import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { IDocumenType } from 'src/app/@interfaces/authentication/documenType';
import { UsersService } from 'src/app/@pages/users/users.service';
import { ValidatorsService } from 'src/app/@shared/validator/validators.service';
import { MaterialModule } from 'src/app/material.module';
import { Observable } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, take } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertsService } from '../../../services/alerts/alerts.service';

@Component({
    selector: 'app-forgot-password',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        TranslateModule
    ],
    templateUrl: `./forgot-password.component.html`,
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    @Input() documentTypes!: IDocumenType[];
    errorMessage: string = '';
    types$?: Observable<IDocumenType[]>;
    forgotPasswordForm = this.fb.group({
        document_type: ["", Validators.required],
        document: [, [Validators.required, Validators.pattern(this.validatorService.documentPattern)]],
    })

    constructor(private fb: FormBuilder,
        private validatorService: ValidatorsService,
        private userService: UsersService,
        private translateService: TranslateService,
        private alertService: AlertsService,
        private dialogRef: MatDialogRef<ForgotPasswordComponent>
    ) {
    }

    ngOnInit() {
        const docTypesNameToShow = ['NIT', 'NIT de Extranjería', 'Tarjeta de identidad', 'Cédula de ciudadanía', 'Tarjeta de extranjería', 'Cédula de extranjería', 'Pasaporte']
        this.types$ = this.userService.listDocumenTypes().pipe(map(types => types.filter((type: any) => docTypesNameToShow.includes(type.description))));
    }

    textValid() {
        this.forgotPasswordForm.markAllAsTouched();
        if (this.forgotPasswordForm.get("document")?.errors?.required || this.forgotPasswordForm.get("document_type")?.errors?.required) {
            return this.errorMessage = this.translateService.instant(
                "Document type and document fields are required"
            )
        }
        return this.errorMessage = this.translateService.instant(
            "Incorrect document. Please verify your document"
        )
    }

    resetPassword() {
        if (this.forgotPasswordForm.invalid) return this.textValid();
        const document_type = parseInt(this.forgotPasswordForm.get('document_type')!.value!);
        const document = parseFloat(this.forgotPasswordForm.get('document')!.value!);
        this.userService
            .verifyDocuments(document_type, document)
            .pipe(take(1))
            .subscribe({
                next: (result) => {
                    if (result) {
                        this.alertService.showSuccessfullAlert(`${this.translateService.instant(
                            "A restoration mail was sent to: "
                        )} ${result.email}`).finally(() => {
                            this.dialogRef.close();
                        })
                    }
                },
                error: (err) => {
                    if (err.graphQLErrors) {
                        const [graphQLError] = err.graphQLErrors;
                        return this.alertService.showWarningAlert(this.translateService.instant(graphQLError.message)).finally(() => {
                        });
                    }
                    return this.alertService.showErrorAlert(
                        this.translateService.instant('Something went wrong. Please check with an administrator')
                    ).finally(() => {
                        this.dialogRef.close();
                    });;
                }
            })
    }
}

